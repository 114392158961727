/*BIG X blurr settings*/
.bigX-blurr-effect-area {
  width: 730px;
  height: 730px;
  perspective: 1000px;
  position: absolute;
  top: 10%;
  z-index: -3;
}
.bigX-objects{
  position:absolute;
  filter: blur(60px);
  opacity: 0.75;
  border-radius: 10000px;
  animation: swing-60deg-wobble-20deg 13s infinite linear;
}
.bigX-object-one {
  background-color: var(--primary-color);
  height: 800px;
  width: 250px;
  top: -35px;
  left: 220px;
  z-index: -3;
}
.bigX-object-two {
  background-color: var(--secondary-color);
  height: 250px;
  width: 800px;
  top: 210px;
  left: -35px;
  z-index: -2;
}
@media (max-width: 768px) {

  .bigX-blurr-effect-area {
    width: 200px;
    height: 200px;
    perspective: 150px;
    position: absolute;
    top: 10%;
    z-index: -3;
  }
  .bigX-objects{
    position:absolute;
    filter: blur(20px);
    opacity: 0.75;
    border-radius: 10000px;
    animation: swing-60deg-wobble-20deg 13s infinite linear;
  }
  .bigX-object-one {
    background-color: var(--primary-color);;
    height: 200px;
    width: 80px;
    top: 5px;
    left: 55px;
    z-index: -3;
  }
  .bigX-object-two {
    background-color: var(--secondary-color);
    height: 80px;
    width: 200px;
    top: 65px;
    left: 0px;
    z-index: -2;
  }

}

/*SMALL X blurr settings*/
.smallX-blurr-effect-area {
  width: 290px;
  height: 290px;
  perspective: 290px;
  position: absolute;
  z-index: -3;
}
.smallX-objects{
  position:absolute;
  z-index: -3;
  filter: blur(35px);
  opacity: 0.75;
  border-radius: 10000px;
  animation: swing-60deg-wobble-20deg 13s infinite linear;
}
.smallX-object-one {
  background-color: var(--primary-color);
  width: 100px;
  height: 300px;
  top: -10px;
  left: 100px;
}
.smallX-object-two {
  background-color: var(--secondary-color);
  width: 300px;
  height: 100px;
  z-index: -2;
  top: 100px;
  left: 1px;
}

/*Animation for BIG and SMALL blur effect X*/
@keyframes swing-60deg-wobble-20deg {
  0% {
    transform: rotateX(0) rotateY(0) rotateZ(30deg);
  }
  25%{
    transform: rotateX(5deg) rotateY(5deg) rotateZ(50deg);
  }
  50% {
    transform: rotateX(10deg) rotateY(-10deg) rotateZ(60deg);
  }
  75%{
    transform: rotateX(5deg) rotateY(5deg) rotateZ(40deg);
  }
  100% {
    transform: rotateX(0) rotateY(0) rotateZ(30deg);
  }
}

/*Big Blob blur general settings */
.square-object-wrapper{
  width: 500px;
  height: 500px;
  position: absolute;
  border-radius: 10000000px;
  overflow: hidden;
  opacity: 0.6;
  filter: blur(120px);
  animation: spin 20s linear infinite;
}
.square-object-wrapper *{
  position: fixed;
  width: 1000px;
  height: 1000px;
  z-index: -3;
}

.square-object-one {
  transform-origin: 60px  60px;
  animation: rotate-360-start-0 20s linear infinite; 
  z-index: -3;
}
.square-object-two {
  transform-origin: 60px 60px;
  animation: rotate-360-start-120 20s linear infinite; 
  z-index: -3;
}
.square-object-three {
  transform-origin: 60px 60px;
  animation: rotate-360-start-240 20s linear infinite;
  z-index: -3;
}

/*Big Blob blur color variations*/
.blurVariation-one-1{
  background-color: var(--primary-color);
}
.blurVariation-two-1{
  background-color: var(--secondary-color);
}
.blurVariation-three-1{
  background-color: var(--tertiary-color);
}

.blurVariation-one-2{
  background-color: var(--tertiary-color);
}
.blurVariation-two-2{
  background-color: var(--primary-color);
}
.blurVariation-three-2{
  background-color: var(--secondary-color);
}

.blurVariation-one-3{
  background-color: var(--secondary-color);
}
.blurVariation-two-3{
  background-color: var(--tertiary-color);
}
.blurVariation-three-3{
  background-color: var(--primary-color);
}

@media (max-width: 768px) {
  .square-object-wrapper{
    opacity: 0.8;
    width: 350px;
    height: 350px;
  }
}

@keyframes rotate-360-start-0 {
  from {
      transform: rotateZ(0deg);
  }
  to {
      transform: rotateZ(360deg); 
  }
}

@keyframes rotate-360-start-120 {
  from {
      transform: rotate(120deg); 
  }
  to {
      transform: rotate(480deg);
  }
}

@keyframes rotate-360-start-240 {
  from {
      transform: rotate(240deg);
  }
  to {
      transform: rotate(600deg); 
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}