.contact-form__name-email-wrapper{
  display: flex;
  gap: 20px
}

.contact-form {
  margin-top: 30px;
  width: 100%;
  background: var(--primary-color-dim); 
  padding: 40px;
  border-radius: 15px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: min(90vw, 700px);
  box-shadow: 0 -50px 800px var(--primary-color-dim-20),0 0 20px rgba(0, 0, 0, 0.3) inset;
}



.contact-form input,
.contact-form textarea {
  background-color: rgba(0, 0, 0, 0.40) !important;
  color: var(--title-color) !important;
  font-size: 16px !important; 
  width: 100%;
  padding: 12px;
  border-radius: 15px;
  box-sizing: border-box !important;
  resize: none !important;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.10) inset !important;
  font-weight: bold;
  margin-bottom: 5px;
  outline: none !important;
}
.contact-form input:focus,
.contact-form textarea:focus{
  color: var(--title-color) !important;
}

.contact-form input{
  height: 40px;
  margin-bottom: 30px;
}


.contact-form textarea {
  min-height: 300px;
}

.contact-form__button {
  width: 100%;
  padding: 12px;
  background-color: rgba(0, 0, 0, 0.4) !important;
  border-radius: 15px;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  border: none;
  transition: 0.6s ease;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.10) inset !important;
  -webkit-tap-highlight-color: transparent; /* Disable tap highlight */
}

.contact-form__button:hover {
  background-color: var(--primary-color-dim) !important;
  transition: 0.6s linear;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.10) !important;
  border-radius: 15px !important;
}
.contact-form__button:active {
  width: 100%;
  padding: 12px;
  background-color: rgba(0, 0, 0, 0.50) !important;
  border-radius: 15px;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  border: none;
  transition: 0.6s ease;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.10) inset !important;
  -webkit-tap-highlight-color: transparent; /* Disable tap highlight */
}

.feedback-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: right;
  font-size: 14px; 
  color: #bbb; 
  margin-left: 5px;
  margin-bottom: 18px;
}
.character-count{
  margin-left: 5px;
}
.invalid-input-message{
  text-align: left;
  color: #ff0000;
  font-weight: bold;
}

.error-message {
  margin: 5px 0 0 5px;
  color: #ff0000;
  font-weight: bold;
  font-size: 0.8em;
}

@media (max-width: 768px) {

  .contact-form__name-email-wrapper{
    flex-direction: column;
  }
  .contact-form__name-email-wrapper{
    gap: 0px;
  }

}

@media (max-width: 480px) {
  .contact-form {
    padding: 25px;
  }
  .contact-form input,
.contact-form textarea {
    margin-bottom: 25px;
  }
  .contact-form textarea {
    min-height: 300px;
    margin-bottom: 5px;
  }
  .character-count{
    margin-left: 20px;
  }
}