.nav_burger-menu {
    display: hidden;
}
.nav_burger-menu {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px !important;
    height: 40px !important;
    border: 2px solid var(--primary-color-dim-40);
    border-radius: 8px;
    cursor: pointer;
}

.nav_burger-menu:active {
    background-color: transparent;
    border-radius: 8px;
}
.nav_burger-menu_button-wrapper{
    margin-top: 18px;
}

.nav_burger-menu_button-wrapper .navButton{
    width: 100%;
    margin-left: 8vw;
}
.burger-bar{
    background-color: var(--primary-color-dim-40);
    width: 20px;
    height: 3px;
    margin: 7px 0 7px 0;
    border-radius: 200px;
}

.header-burger-panel{
    display: flex;
}
.nav-links {
    display: hidden;
    flex-direction: column;
    width: 100%;
    background: #333;
    position: absolute;
    top: 60px;
    left: 0;
    transition: transform 0.3s linear forwards;
}
.nav_burger-menu-content {
    position: fixed;
    background-color: var(--background-color);
    top: 0px;
    right: 0px;
    height: 120vh;
    width: 98vw;
    z-index: 4;
}
.nav_burger-menu-exit-button{
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80px;
    width: 80px;
    top: 0px;
    right: 0px;
    cursor: pointer;
    z-index: 5;
}
.nav_burger-menu-exit-icon{
    position: absolute;
    height: 30px;
    width: 30px;
    color: var(--primary-color);
}

.nav_burger-menu-blur{
    position: fixed;
    top: 0;
    left: 0;
    height: 120vh;
    width: 5vw;
    background: var(--primary-color);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 3;

}

@keyframes fadeIn {
    0% {
       
        transform: translateX(100vw);
     
    }
    100% {
      
        transform: translatex(0);
    }
}
@keyframes fadeOut {
    0% {
        transform: translatex(0);
     
    }
    100% {
        transform: translateX(100vw);
       
    }
}
@keyframes fadeInBlur {
    0% {
        transform: translateX(100vw);
       
    }
    100% {
        transform: translateX(0%);

    }
}
@keyframes fadeOutBlur {
    0% {
        transform: translateX(0%);
  
    }
    100% {
        transform: translateX(100vw);
     
    }
}
.fade-in {
    animation: fadeIn 0.7s forwards;
}
.fade-out {
    animation: fadeOut 0.7s forwards;
}
.fade-out-instant {
    display: hidden;
}