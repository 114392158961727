.employee-card_list{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: top;
  width: min(90vw, 990px);
  gap: 20px;
  background: transparent;
  font-size: 16px;
}
.employee-card{
  display: flex;
  border-radius: 10px;
  height: 80px;
  width: 250px;
}
.employee-card_photo{
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.employee-card_title-wrapper{
  padding-left: 15px;
  text-decoration: none;
}
.employee-card_name{
  color: var(--title-color);
  font-size: 1em;
}
.employee-card_role{
  color: var(--description-color);
  font-size: 0.95em;
  margin: 0 0 4px 0;
}
.employee-card_email{
  color: var(--primary-color);
  opacity: 1;
  font-weight: bold;
  font-size: 0.85em;
  cursor: pointer;
  text-decoration: none;
}

@media (max-width: 768px) {
  .employee-card_list{
    gap: 30px;
  }
}

@media(max-width: 480px) {
  .employee-card_list{
    gap: 30px;
 }
}

