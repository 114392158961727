.company-intro-content {
  display: flex;
  align-items: center;
  justify-content: center;
  width: min(90vw, 9600px);
  margin-bottom: 60px;
}

.company-intro-title {
  font-size: 2em; 
  color: var(--title-color);
  display: inline; 
  vertical-align: baseline;

}











.contact__intro-list{
  display: flex;
  justify-content: center;
  align-items: center;
}
.contact__title{
  color: var(--title-color);
}
.contact__intro-text{
  color: var(--description-color);
  text-align: center;
}
.contact__intro-area{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: min(80vw, 960px);
}










.hero-banner-wrapper{
  position: relative;
  display: flex;
  width: min(90vw, 960px);
  margin-bottom: 100px;
  offset-anchor: 50%;
}
.hero-banner-text-content{
  display: flex;
  align-items: center;
  width: 500px;
  display: flex;
}
.hero-banner-text-align{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  height: auto;
  gap: 10px; 
}
.hero-banner-text{
  color: var(--primary-color);
  font-size: 4em;
}
.hero-banner-img{
 width: min(120vw, 500px);
 border-radius: 15px;
}
.hero-banner-blur-effekt{
  background: var(--primary-color-dim);
  border-radius: 1000px;
  position: absolute;
  top: 50%;     /* Center vertically */
  left: 50%;    /* Center horizontally */
  transform: translate(-50%, -50%);  /* Adjust the anchor point to the center of the element */
  width: min(70vw, 280px);
  height: min(70vw, 280px);
  filter: blur(min(40vw, 250px));
  z-index: -1;
}







.hero-section-wrapper{
  display: flex;
  flex-direction: column;
  width: min(90vw, 960px);
  justify-content: center;
}
.hero-section-title{
  font-size: 2em;
  color: var(--title-color);
  text-align: center;
  margin-bottom: 30px;
  font-weight: bold;
}
.hero-section-services-list{
  width: min(90%, 960px)
}
.hero-section-text-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 100px;
}
.hero-section-text{
  color: var(--description-color);
  font-size: 1.1em;
  text-align: center;

}








.contact-wrapper{
  align-self: center;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.contact-content{
  width: 500px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  text-align: center;

}
.contact-content *{
  font-family: 'roboto';
  color: grey;
  font-weight: lighter !;
}
.contact-text-and-link{
  display: flex;
  gap: 5px;
  justify-content: center;
}
.contact-text-link{
  font-size: 0.9rem;
  color: var(--primary-color);
}

.contact-content h1{
  font-size: 3rem;
}
.contact-title{
  font-size: 1.2rem;
  margin-bottom: 15px;
}

.contact-text{
  font-size: 0.9rem;
}
.contact-top-spacer{
  height: 90px;
}
.contact-spacer{
  height: 50px;
}











/*.services-list{
  width: min(90vw, 960px);
  list-style: none;
  margin-bottom: 60px;
}
.services-list li{
  display: flex;
  justify-content: space-between;
  width: min(90vw, 960px);
  border-top: 2px solid var(--primary-color-dim);
  padding: 20px 0 20px 0;
}
.services-title{

}
.services-title-wrapper{
  display: flex;
  align-items: center;
  font-size: 1.25em;
  color: var(--title-color);
  width: 40%;
}
.services-text{
  color: var(--description-color);
}
.services-text-wrapper{
  width: 55%;
  display: flex;
  align-items: center;
  padding-left: 5%;
}*/





















@media (max-width: 1070px) {
  .hero-banner-wrapper{
    flex-direction: column;
    align-items: center;

  }
  .hero-banner-text-content{
    justify-content: center;
    margin-bottom: 60px;
  }
  .hero-banner-text-align{
    text-align: center;
  }
  .hero-banner-text{

  }

  .hero-banner-img{
    left: 0px;
    top: -300px;
    background: cover;
  }
  .hero-banner-blur-effekt{
    
  }




  .contact-content{
    width: 700px;
    align-self: center;
    padding: 20px;
  }
  .contact-content *{
    font-family: 'roboto';
    color: grey;
    font-weight: lighter !;
  }
  
  .contact-content h1{
    font-size: 3rem;
  }
  .contact-title{
    font-size: 1.2rem;
    margin-bottom: 15px;
  }
  
  .contact-text{
    font-size: 0.9rem;
  }
  .contact-top-spacer{
    height: 90px;
  }
  .contact-spacer{
    height: 50px;
  }





  .services-list{

  }
  .services-list li{

  }
  .services-title{
    font-size: 0.8em;
  }
  .services-title-wrapper{

  }
  .services-text{
    font-size: 1em;
  }
  .services-text-wrapper{
    display: flex;
    align-items: center;
  }
}


@media (max-width: 768px) {
  .company-intro-content {
    margin-bottom: 20px;
  }
  .company-intro-title{
    font-size: 2em;
  }





  .hero-banner-wrapper{
    margin-bottom: 20px;
  }
  .hero-banner-text-content{
    margin-bottom: 40px;
    align-items: center;
  }
  .hero-banner-text-align{
    justify-content: center;

  }
  .hero-banner-text{
    font-size: 3em;
  }
  .hero-banner-text{
   
  }
  .hero-banner-blur-effekt{
 
  }
  .hero-banner-img-cover{
    height: 400px;
  }
  .hero-banner-img{
    top: -150px;
  }



  .hero-section-wrapper{
  }
  .hero-section-title{
    font-size: 2em;
    margin-bottom: 20px;
  }
  .hero-section-services-list{

  }
  .hero-section-text-wrapper {
    margin-bottom: 20px;
    font-size: 0.9em;
  }
  .hero-section-text{
    text-align: left;
  }



  .services-list{
    margin-bottom: 20px;
  }
  .services-list li{
    flex-direction: column;
  }
  .services-title{
    font-size: 0.7em;
  }
  .services-title-wrapper{
    width: 100%;

  }
  .services-text{

  }
  .services-text-wrapper{
    width: 90%;
    display: flex;
    align-items: center;

  }


  .contact-content{
    width: 90vw;
    align-self: center;
    padding: 0px;
  }
  .contact-content *{
    font-family: 'roboto';
    color: grey;
    font-weight: lighter !;
  }
  
  .contact-content h1{
    font-size: 2rem;
  }
  .contact-title{
    font-size: 1rem;
    margin-bottom: 10px;
  }
  
  .contact-text{
    font-size: 0.8rem;
  }
  .contact-top-spacer{
    height: 50px;
  }
  .contact-spacer{
    height: 30px;
  }









  
}




@media(max-width: 480px) {
  .company-intro-title{
    font-size: 1.5em;
  }



  .hero-banner-text{
    font-size: 3em;
  }



  .hero-section-wrapper{

  }
  .hero-section-title{
    font-size: 1.5em;
  }
  .hero-section-text{
    text-align: center;
  }
  .hero-section-services-list{

  }




  .services-list{

  }
  .services-list li{

  }
  .services-title{

  }
  .services-title-wrapper{

  }
  .services-text{

  }
  .services-text-wrapper{

  }
  
}