.product-header__wrapper{
    width: min(90%, 960px);
    height: 70vh;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    gap: 50px;
}


.product-header__text-content{
    display: flex;
    align-items: left;
    justify-content: center;
    flex-direction: column;
    width: 450px;
    padding : 20px;
    border-radius: 15px;
}

.product-header__title{
    color: var(--title-color);
    font-size: 3em;
    margin-bottom: 20px;
}
.product-header__text{
    color: var(--description-color);
    font-size: 1.3em;
    line-height: 1.2em;
}
.product-header__img{
    width: min(50%, 400px) !important;
    border-radius: 5px;
}






.product-perk__list{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    margin: 20vh 0;
    gap: 20vh;
}
.product-perk__wrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 100px;
    width: min(90vw, 960px);
    border-radius: 15px;
}
.product-perk__info-wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
    width: 50% !important;
    padding: 30px;
    border-radius: 15px;
}
.product-perk__title{
    color: var(--title-color);
    font-size: 1.3em;
}
.product-perk__description{
    color: var(--description-color);
    font-size: 1.1em;
    line-height: 1.2em;

}
.product-perk__img-align{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 50%  !important;
}
.product-perk__img{
    width: 200px;
    border-radius: 15px;
}





.hidden-left-product-header {
    opacity: 0;
    filter: blur(15px);
    transition: all 0.7s;
    transform: translateX(-50vw);
  }
  .hidden-left-product-perk{
    opacity: 0;
    filter: blur(15px);
    transition: all 0.7s;
    transform: translateX(-50vw);
  }
  .hidden-right-product-perk {
    opacity: 0;
    filter: blur(15px);
    transition: all 0.7s;
    transform: translateX(50vw);
  }
  
  .show{
    opacity: 1;
    filter: blur(0px);
    transform: translatex(0);
  }





@media (max-width: 768px) {
    .product-header__wrapper{
        margin-top: 10vh;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        gap: 50px;
    }
    .product-header__text-content{
        width: 80vw;
        align-items: center;
        justify-content: center;
        gap: 20px;
        text-align: center;
    }
    .product-header__title{

    }
    .product-header__text{

    }
    .product-header__img{
        width: calc(20vw + 250px) !important;
    }
    
    
    
    
    
    
    .product-perk__list{
    
    }
    .product-perk__wrapper{
        flex-direction: column;
        width: 400px;
    }
    .product-perk__info-wrapper{
        width: 80vw !important;
    }
    .product-perk__title{
       
    }
    .product-perk__description{
        
    
    }
    .product-perk__img-align{
  
    }
    .product-perk__img{
    
    }
}

@media (max-width: 480px) {
    .product-header__wrapper{
        min-height: 600px;
        height: 80vh;
        gap: 10px;
        margin-top: 0;
        width: 85vw;

    }
    .product-header__text-content{
        width: 100% ;
        gap: 5px;
    }
    .product-header__title{
        font-size: 2em;
        margin: 0;
    }
    .product-header__text{
        font-size: 1.1em;
    }
    .product-header__img{
        width: 90% !important;
    }
    
    
    
    
    
    
    .product-perk__list{
        gap: 20vh;
        width: 85vw;
        justify-content: space-around;
    }
    .product-perk__wrapper{
        width: 100% ;
        gap: 20px;
    }
    .product-perk__info-wrapper{
        width: 85% !important;
        text-align: left;
        padding: 20px;
        gap: 10px;
    }
    .product-perk__title{
       font-size: 1em;
    }
    .product-perk__description{
        font-size: 1em;
        
    
    }
    .product-perk__img-align{
  
    }
    .product-perk__img{
    
    }
}