/* Sets default font and removes default settings for margin and padding */
*{
    margin: 0;
    padding: 0;
}

header {
    width: 100%;
    height: 40px;
    padding: 20px 0 20px 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    margin-bottom: 60px;
}
.header-wrapper{
    width: min(80vw, 1100px);
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header-company-name{
    font-family: 'Rubik Mono One', sans-serif;
    font-size: 22px;
    font-weight: none;
    color: #ffffff;
    margin-left: 10px;
    cursor: pointer;
    text-decoration: none;
}
.header_burger-menu_spacing{
    margin-left: 10px;
    -webkit-tap-highlight-color: transparent;
}
.header_burger-menu_spacing:active {
    background-color: transparent;
}
nav{
    display: flex;
    gap: 20px;
}

.nav{
    height: 40px;
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;
}

.url-adress-error-message{
    color: var(--title-color);
    font-size: 4em;
    margin: auto;
}

footer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    width: 100%;
    border-top: 1px solid var(--primary-color);
    margin-top: 60px;
}
.footer-wrapper{
    display: flex;
    width: min(80vw, 1100px);
    align-items: center;
    justify-content: space-between;
}
.footer-left-wrapper{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
}
.footer-company-name{
    font-family: 'Rubik Mono One', sans-serif;
    font-size: 22px;
    color: #ffffff;
    cursor: pointer;
    text-decoration: none;
}
.footer-copyright-text{
    color: white;
    margin-left: 20px;
}
.footer-right-wrapper{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}
.footer-right-wrapper *{
    margin-left: 20px;
}

.show-on-small-screen{
    display: none !important;
}
.show-on-big-screen{
    display: flex;
}


.background-wrapper {
    position: relative;
    width: 300px;
    height: 200px;
}

.glass-background {  
    border-radius: 15px;
    position: absolute;
    background: hsla(0, 0%, 100%, .01);
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(10px);
    z-index: -1;
    box-shadow: 0px 10px 35px rgba(255, 255, 255, 0.05)inset, 0px 0px 2px rgba(255, 255, 255, 0.1)inset;
}
.darkgrey-background{
    border-radius: 15px;
    position: absolute;
    background: #1d1c1c;
    top: 0px;
    left: 0px;
    opacity: 0.07;
    width: 100%;
    height: 100%;
    z-index: -1;
    box-shadow: 0px 10px 35px rgba(255, 255, 255, 0.05)inset, 0px 0px 2px rgba(255, 255, 255, 0.1)inset;
}



@media (max-width: 1200px) {
    .footer-wrapper{
        display: flex;
        width: 90vw;
        align-items: center;
        justify-content: space-between;
    }
}

@media (max-width: 768px) {
    header{
        margin-bottom: 0px;
    }
    .header-wrapper{
    width: 90vw;
    }
    .error-message{
        font-size: 2em;
    }
    footer{
        margin-top: 30px;
    }
    .show-on-small-screen{
        display: flex !important;
    }
    .show-on-big-screen{
        display: none !important;
        width: 0px;
        height: 0px;
    }
}

@media (max-width: 550px) {
    footer{
        height: 140px;
    }
    .footer-wrapper{
        flex-direction: column;
        gap: 15px;
    }
}

@media (max-width: 480px) {
    .header-wrapper{
        width: 90vw;
    }
    footer{
        height: 120px;
        flex-direction: column;
    }
}

@media (max-width: 350px) {
    .header-wrapper{
        width: 95vw;
    }
}