
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik+Mono+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap');

@font-face {
  font-family: 'Thicc Boi';
  src: url('./fonts/THICCCBOI-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}
html {
  font-size: 18px;
}

*{
  margin: 0;
  padding: 0;
  font-family: 'Thicc Boi';
  border: none;
}

input:-webkit-autofill,
textarea:-webkit-autofill { /* Chrome */
  background-color: transparent !important; 
  -webkit-box-shadow: 0 0 0 1000px transparent inset !important;
  box-shadow: 0 0 0 1000px transparent inset !important; 
  -webkit-text-fill-color: white !important;
  transition: background-color 5000s ease-in-out 0s;
}
input:-moz-autofill,
textarea:-moz-autofill { /* Firefox */
  background-color: var(--primary-color-dim) !important; 
  color: white !important; 
  border: 1px solid transparent !important;
}
input:-ms-autofill,
textarea:-ms-autofill { /* Edge */
  background-color: var(--primary-color-dim) !important;
  color: white !important; 
  border: 1px solid transparent !important;
}




::-webkit-scrollbar {
  width: 16px; 
}
::-webkit-scrollbar-track {
  background-color: rgba(20, 20, 20, 0.8); 
  border-radius: 15px; 
  border: 2px solid rgba(30, 30, 30, 0.9);;
  box-sizing: border-box; 
  cursor: default;
}
::-webkit-scrollbar-thumb {
  background-color: var(--primary-color-dim); 
  border-radius: 15px; 
  border: 2px solid rgba(30, 30, 30, 0.9);; 
  box-sizing: border-box; 
}
::-webkit-scrollbar-thumb:hover { 
  cursor: default;
}




:root {
  --primary-color: rgba(39, 222, 191, 1);
  --primary-color-dim: rgba(39, 222, 191, 0.6);
  --primary-color-dim-40: rgba(39, 222, 191, 0.4);
  --primary-color-dim-30: rgba(39, 222, 191, 0.3);
  --primary-color-dim-20: rgba(39, 222, 191, 0.2);
  --primary-color-dim-10: rgba(39, 222, 191, 0.1);
  --primary-color-dim-5: rgba(39, 222, 191, 0.05);
  --secondary-color: #153e96;
  --tertiary-color: #ff4895;
  --background-color: #000808;
  --title-color: #F2F2F2;
  --text-color: white;
  --description-color: #D3D3D3;
  --text-background: rgba(42, 40, 49, 0.7);
}





.hide-on-mobile{
  display: none !important;
}
.show-on-mobile{
  display: flex !important;
}
@media (min-width: 480px){
  .hide-on-mobile{
    display: flex !important;
  }
  .show-on-mobile{
    display: none !important;
  }
}




.zoom-on-hover-5{
  transition: transform 0.2s ease;
}
.zoom-on-hover-5:hover {
  transform: scale(1.05);
  transition: transform 0.2s ease;
}

.zoom-on-hover-2{
  transition: transform 0.2s ease;
}
.zoom-on-hover-2:hover {
  transform: scale(1.02);
  transition: transform 0.2s ease;
}







body {
  background-color: var(--background-color);
  background-size: 100% 100%;
  width: 100vw;
  overflow-x: hidden !important;
  display: flex;
  justify-content: center;
}








#root{
  width: 100%;
}

.app{
  overflow-x: hidden !important;
  display: flex;
  justify-content:space-between;
  flex-direction: column;
  min-height: 100vh;

}

.main{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 100%;
}