.contact-button {
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    width: 106px;
    height: 40px;
    background-color: var(--primary-color-dim-40);
    border-radius: 15px; /* Ensure border radius is applied */
    cursor: pointer;
    color: white;
    font-weight: bold;
    -webkit-tap-highlight-color: transparent; /* Disable tap highlight */
    transition: background-color 0.5s ease; /* Smooth transition on hover */
}

.contact-button:hover {
    transition: 0.5s ease; /* Smooth transition on hover */
    background-color: var(--primary-color-dim); /* Change background color on hover */
    cursor: pointer;
}

.contact-button:active {
    background-color: var(--primary-color-dim-40); /* Maintain background color on tap */
    border-radius: 15px; /* Ensure border radius is applied */
}

.navButton {
    display: flex;
    align-items: center;
    background-color: transparent;
    border: 0px solid transparent;
    color: rgb(255, 255, 255);
    text-decoration: none;
    font-size: 1em;
    width: auto;
    cursor: pointer;
    height: 40px;
    white-space: nowrap;
    margin-left: 10px;
    font-weight: bold;
    -webkit-tap-highlight-color: transparent; /* Disable tap highlight */
    transition: color 0.5s ease; /* Smooth transition on hover */
}

.navButton:hover {
    color: var(--primary-color);
    cursor: pointer;
    transition: 0.5s ease; /* Smooth transition on hover */
}

.navButton:active {
    background-color: transparent; /* Maintain background color on tap */
    border-radius: 0; /* Ensure border radius is applied */
}